.compuerta{
    text-align: justify;
    text-size-adjust: 90%;
    
}
.alturario{
    position:relative; 
    top:-180px; 
    left:5px; 
    visibility:visible;
     z-index:0.5;
     
}

.titulorio{
    position:relative; 
    top:-350px; 
    left:205px; 
    right: 10px;
    visibility:visible;
    font-size: small;
     z-index:0.7;
     color:rgba(82, 82, 81, 0.99);
}
.barra{
    position:relative; 
    top:-13px; 
    left:-65px; 
    visibility:visible;

}