h4.color-text{
    color:black;
    margin-left: 15px;
}
.lluvia{

    margin-left: 1px;
}
.row-header-corto{
    display: flex;
    align-items: center;
}

.ionTolbar-corto{
    --background: rgb(142, 72,100,0.95);
   
}
.modal-button-corto{
    background: rgb(142, 72,100,0.95);
    color: white;
}

.color-fondo-corto{
    --background: rgb(142, 72,100,0.25);
}
.foto{
    margin: auto;
}
.ionTolbar-corto .color-text{
    text-align: center;
    color: white;
}
.color-text{
    color: black;
    margin-left: 5px;
}

.texto{
    color:rgb(255,255,255,0.99) ;
    padding: 2px;
    text-align: center;
    
    font-size:65% ;

}
.items{
    padding:0%;
    margin: 0%;
    
    
}
.icono{
    color: black;
}

.modal-footer{
    background: rgba(28, 126, 101, 0.33);
}
.color-fondo{
    --background: rgba(28, 126, 101, 0.33);
}
.modal-button{
    color:  white;
 background: rgba(153, 198, 187, 0.89); 
}
.modal-class{

    --border-radius: 10px;
    --height: 55Vh;
    --width:80Vw ;
}
.dias{
text-align: center;

}

.extendidoVertical{
    
    background: rgba(82, 82, 81, 0.50);
    border: solid 1px #ddd;
    
    margin: 3px;
    
}
.extendido{
    background: rgba(82, 82, 81, 0.30);
    margin: O;
}


ion-card-subtitle{
    color: rgb(247, 242, 242);
}
ion-card-content{
    color: white;
}
ion-card-title.titulo{
   color: rgb(247, 242, 242);
}
ion-icon{
    color:  rgb(247, 242, 242);
    
    margin-right: 1px;
}
ion-datetime{
    color:  rgb(255, 255, 255);
  
    padding: 0px;
    margin: 0px;
}

#fotohead{

    margin-left: 30px;
}
ion-text{
   
    font-size: 15px;
}

.texto-dia{
    color: rgb(255,255,255,0.99);
    opacity: 0.99;
}
.lluvia {
    /* margin-left: 1px; */
    /* justify-items: center; */
    /* justify-items: center; */
    justify-content: center;
}

.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal>.swiper-pagination-bullets {
    bottom: -5px;
    left: 0;
    width: 100%;
    /* margin-top: 20px; */
}