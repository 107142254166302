ion-toolbar {

    --background: rgba(153, 198, 187, 0.89);
    
}

.logoareco{
    
    margin: 20px;
    

}
p{
    text-align: left;
    margin-left: 10px;
    margin-right: 10px;
}
.logobanner{
   
    width: 100%;
}