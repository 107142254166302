.input-wrapper *{
    display: flex;
}

ion-card{

    background:rgba(82, 82, 81, 0.99) ;
    margin-right: 1.3px;
    margin-left: 1.3px;
    margin-top: 0px;
   
}

ion-item{
    --background: rgba(228, 208, 208, 0.014);
   
}
ion-card-subtitle{
    color: rgb(247, 242, 242);
}
ion-card-content{
    color: white;
}
ion-card-title{
    color: rgb(247, 242, 242);
}
ion-icon{
    color:  rgb(247, 242, 242);
    
    margin-right: 1px;
}
ion-datetime{
    color:  rgb(255, 255, 255);
  
    padding: 0px;
    margin: 0px;
}

#rio{
 
position:relative; 
margin-top: 13px;
top: -3px;
bottom: 30px;
width: 99.7%;
height: 180px;
margin-left: 0px;
border-radius: 3px;
background-color:rgba(153, 198, 187, 0.55) ;
}
.content{
    top:2.5px; 
    bottom: 20px;
    width: 97.7%;
    margin-left: 5px;
    border-radius: 3px;
    background-color:rgba(82, 82, 81, 0.30) ;
}
@media only screen and (min-width:768px){
    #rio{
        position:relative; 
        margin-top: 17px;
        top: -5px;
        bottom: 20px;
        left:-20px; 
        width: 98.5%;
        height: 180px;
        margin-left: 27px;
        margin-right: 10px;
        background-color:rgba(153, 198, 187, 0.55) ;
        }
}

.content{
    top:2.5px; 
    bottom: 20px;
    width: 98.5%;
    margin-left: 5px;
    border-radius: 3px;
    background-color:rgba(82, 82, 81, 0.30) ;
}

@media only screen and (min-width:1024px){
    #rio{
        position:relative; 
        margin-top: 17px;
        
        top:-5px; 
        bottom: 20px;
        left:-20px; 
        width: 99%;
        height: 180px;
        margin-left: 27px;
        margin-right: 27px;
        background-color:rgba(153, 198, 187, 0.55) ;
        }

        .content{
            top:2.5px; 
            bottom: 20px;
            width: 99%;
            margin-left: 5px;
            border-radius: 3px;
            background-color:rgba(82, 82, 81, 0.30) ;
        }
}
